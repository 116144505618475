<template>
  <div class="source-page">
    <!-- <a-page-header title="Source" class="pl-0">
      <small>Description about custom sources</small>
    </a-page-header> -->
    <a-row :gutter="25">
      <a-col>
        <div class="source-title">Source</div>
      </a-col>
    </a-row>
    <div class="page-content">
      <a-tabs v-model:activeKey="activeKey" class="custom-tab" :tab-bar-style="{ background: '#F1F2F6' }">
        <a-tab-pane key="facebook" :forceRender="true">
          <template #tab><span class="tab-title">Facebook</span> </template>
          <Facebook v-if="activeKey === 'facebook'"></Facebook>
        </a-tab-pane>
        <a-tab-pane key="instagram" :forceRender="true">
          <template #tab><span class="tab-title">Instagram</span> </template>
          <Instagram v-if="activeKey === 'instagram'"></Instagram>
        </a-tab-pane>
        <a-tab-pane key="youtube" :forceRender="true">
          <template #tab><span class="tab-title">Youtube</span> </template>
          <Youtube v-if="activeKey === 'youtube'"></Youtube>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import Facebook from './Facebook/Index.vue';
import Instagram from './Instagram/Index.vue';
import Youtube from './Youtube/Index.vue';

export default {
  components: { Facebook, Instagram, Youtube },
  name: 'CustomSource',
  setup() {
    const activeKey = ref('facebook');
    return { activeKey };
  },
};
</script>

<style lang="scss" scoped>
.source-page {
  padding: 30px;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  .source-title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 18px;
  }
}
.tab-title {
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.pl-0 {
  padding-left: 0px !important;
}
</style>
