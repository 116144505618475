<template>
  <a-button v-if="!login" size="small" type="primary" class="btn btn-primary" @click="logInWithFacebook"
    >Connect with Facebook</a-button
  >
  <a-button v-else size="small" type="primary" class="btn btn-primary" @click="logOutFacebook"
    >Logout Facebook</a-button
  >
</template>
<script>
import api from '../../services/api';

export default {
  name: 'facebookLogin',
  props: { scopes: String, appId: String, source: String },
  emits: ['authenticated', 'error'],
  data() {
    return {
      login: false,
    };
  },
  created() {
    document.querySelectorAll('script[src*="connect.facebook.net"]').forEach((v) => {
      v.remove();
    });

    this.loadFacebookSDK(document, 'script', 'facebook-jssdk');
    this.initFacebook();
    setTimeout(() => {
      this.checkLogin();
    }, 1000);
  },
  methods: {
    async logInWithFacebook() {
      window.FB.login(
        (response) => {
          console.log(response);
          if (response.authResponse) {
            console.log('You are logged in &amp cookie set!');
            if (response.authResponse.accessToken) {
              if (this.source === 'instagram') {
                api
                  .addInstagramUserAccessToken(response.authResponse.accessToken)
                  .then(() => {
                    this.login = true;
                    this.$emit('authenticated');
                  })
                  .catch(() => {
                    this.$emit('error');
                  });
              } else if (this.source === 'facebook') {
                api
                  .addFanpageByAccessToken(response.authResponse.accessToken)
                  .then(() => {
                    this.login = true;
                    this.$emit('authenticated');
                  })
                  .catch(() => {
                    this.$emit('error');
                  });
              }
            }
          } else {
            console.log('User cancelled login or did not fully authorize.');
          }
        },
        {
          scope: this.scopes,
          // 'pages_show_list,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,pages_read_engagement,pages_manage_posts,public_profile',
        }
      );
      return false;
    },
    async logOutFacebook() {
      window.FB.logout(() => {
        this.login = false;
      });
    },
    async initFacebook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: this.appId, //You will need to change this
          cookie: true, // This is important, it's not enabled by default
          version: 'v13.0',
        });
      };
    },
    loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v14.0&appId=' + this.appId;
      fjs.parentNode.insertBefore(js, fjs);
    },

    async checkLogin() {
      window.FB.getLoginStatus((response) => {
        console.log(response);
        if (response.status === 'connected') {
          this.login = true;
        } else if (response.status === 'not_authorized') {
          this.login = false;
          // The user hasn't authorized your application.  They
          // must click the Login button, or you must call FB.login
          // in response to a user gesture, to launch a login dialog.
        } else {
          this.login = false;
          // The user isn't logged in to Facebook. You can launch a
          // login dialog with a user gesture, but the user may have
          // to log in to Facebook before authorizing your application.
        }
      });
    },
  },
};
</script>
