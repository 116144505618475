<!-- eslint-disable max-len -->

<template>
  <a-card>
    <a-row type="flex" align="middle" justify="space-between" :gutter="10">
      <a-col class="col-center space-between"
        ><span class="title">Instagram Account</span>
        <a-input-search v-model:value="search.q" class="search-input ml-24" placeholder="Search" @input="loadData"
      /></a-col>
      <a-col class="col-center space-between">
        <div><span>Type:</span></div>
        <a-select
          v-model:value="search.type"
          :getPopupContainer="(trigger) => trigger.parentNode"
          class="source-select"
          default-value=""
          @change="loadData"
        >
          <a-select-option value=""> All Types </a-select-option>
          <a-select-option value="All messages"> All messages </a-select-option>
          <a-select-option value="Matched keyword"> Matched keyword </a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <!-- Table will be here -->
        <TableSetting
          :rowKey="'_id'"
          :isRowSelection="true"
          :isActionDropDown="true"
          :isTotalActive="true"
          :isTimeFromNow="true"
          :tableData="filteredSourceList"
          :columns_props="columnConfig"
          :actions="actions"
          :pagination="{
            showQuickJumper: false,
            showSizeChanger: true,
            total: filteredSourceList.length,
            showTotal: () => '',
          }"
          @toggleAction="onToggleAction"
        />
      </a-col>
    </a-row>
    <EditInstagramUser
      :visible="editPageVisible"
      :user-info="userInfo"
      @close="resetEditData"
      @success="resetEditData"
    ></EditInstagramUser>

    <ConfirmDelete :visible="confirmVisible" @close="confirmVisible = false" @confirm="deleteUser">
      Are you sure delete this user?
    </ConfirmDelete>
    <ConfirmDelete :visible="confirmMultipleVisible" @close="confirmMultipleVisible = false" @confirm="deleteMultiple">
      Are you sure delete multiple users?
    </ConfirmDelete>
  </a-card>
</template>

<script>
import { ref, computed, onMounted, reactive } from 'vue';
import EditInstagramUser from './EditInstagramUser.vue';
import ConfirmDelete from '@/components/ConfirmDelete.vue';
import api from '@/services/api';
import { useStore } from 'vuex';
import TableSetting from '@/components/Table/TableSetting.vue';
import { notification } from 'ant-design-vue';
import helper from '@/services/helper';

export default {
  components: { EditInstagramUser, ConfirmDelete, TableSetting },
  setup() {
    const store = useStore();
    const instagramAccounts = computed(() => store.getters['sourceSetting/instagramAccounts']);
    const confirmVisible = ref(false);
    const confirmMultipleVisible = ref(false);
    const editPageVisible = ref(false);
    const userInfo = ref({});
    const search = reactive({
      q: '',
      type: '',
    });
    const columnConfig = [
      {
        title: 'Account name',
        dataIndex: 'username',
        key: 'username',
        slots: { customRender: 'source_name' },
        sorter: helper.getTableSortFn('string', 'username'),
      },
      {
        title: 'Data Collection',
        dataIndex: 'type_txt',
        key: 'type_txt',
        slots: { customRender: 'type' },
        sorter: helper.getTableSortFn('string', 'type_txt'),
      },
      {
        title: 'Date Added',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        slots: { customRender: 'updated_at' },
        sorter: helper.getTableSortFn('date-x', 'updatedAt'),
        defaultSortOrder: 'descend',
      },
      {
        title: 'Actions',
        key: 'action',
        slots: { customRender: 'action' },
      },
    ];

    const actions = [
      { name: 'Open Link', value: 'open_link', icon: 'external-link' },
      { name: 'Edit', value: 'edit', icon: 'edit' },
      { name: 'Delete', value: 'delete', icon: 'trash-2' },
    ];

    const selectedData = ref(null);

    const loadData = async () => {
      await store.dispatch('sourceSetting/getInstagramAccounts');
    };

    const resetEditData = () => {
      editPageVisible.value = false;
      loadData();
    };

    const onEditPage = async (data) => {
      console.log(data);
      userInfo.value = data;
      editPageVisible.value = true;
    };
    const onSearch = (value) => {
      console.log(value);
    };
    const confirmdeleteUser = (data) => {
      confirmVisible.value = true;
      selectedData.value = data;
      console.log('confirm delete user...');
    };
    const deleteUser = async () => {
      // delete api
      let result = await api.deleteInstagramUserByAccountId(selectedData.value.link).catch(() => {});
      if (result && result.code === 200) {
        notification.success({
          message: 'Success',
          description: `User ${selectedData.value.name} removed.`,
        });
      } else {
        notification.error({
          message: 'Error',
          description: `User ${selectedData.value.name} remove failed.`,
        });
      }

      confirmVisible.value = false;
      selectedData.value = null;
      loadData();
    };

    const confirmDeleteMultiple = (data) => {
      console.log([...data]);

      confirmMultipleVisible.value = true;
      selectedData.value = [...data];
      console.log('confirm delete multiple users ...');
    };

    const deleteMultiple = async () => {
      const list = selectedData.value;
      let result = await api.bulkDeleteInstagramUser(list).catch(() => {});
      if (result && result.code === 200) {
        notification.success({
          message: 'Success',
          description: `${list.length} users removed.`,
        });
      } else {
        notification.error({
          message: 'Error',
          description: `${list.length} users remove failed.`,
        });
      }
      console.log('deleted user...');
      confirmMultipleVisible.value = false;
      selectedData.value = null;
      loadData();
    };

    const filteredSourceList = computed(() => {
      let items = (instagramAccounts.value || []).map((v) => {
        return { ...v };
      });
      if (items.length) {
        const searchTerm = search.q.trim();
        if (searchTerm) {
          items = items.filter((v) => v.username.toLowerCase().includes(searchTerm.toLowerCase()));
        }
        if (search.type) {
          items = items.filter((v) => v.type === search.type);
        }
      }
      return items;
    });

    const onToggleAction = ({ action, record }) => {
      if (action === 'edit') {
        onEditPage(record);
      } else if (action === 'delete') {
        confirmdeleteUser(record);
      } else if (action === 'deleteMultiple') {
        confirmDeleteMultiple(record);
      } else if (action === 'open_link') {
        window.open(record.link);
      }
    };

    onMounted(() => {
      loadData();
    });
    return {
      instagramAccounts,
      confirmVisible,
      confirmMultipleVisible,
      editPageVisible,

      userInfo,
      onSearch,
      search,
      loadData,
      resetEditData,

      confirmdeleteUser,
      deleteUser,
      deleteMultiple,

      filteredSourceList,
      columnConfig,
      onToggleAction,
      actions,
    };
  },
};
</script>

<style scoped lang="scss">
.title {
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: '#272B41';
}

.search-input {
  border-radius: 18px;
  width: 250px;
  height: 36px;
}
.ml-24 {
  margin-left: 24px;
}

.col-center {
  display: flex;
  align-items: center;
  padding: 5px;
}
.source-select {
  width: 160px;
  margin-left: 8px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 540px) {
  .space-between {
    justify-content: space-between;
    flex: auto;
  }
}
.user-name {
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 9px;
}

.user-id {
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
</style>
