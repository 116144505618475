<template>
  <div>
    <ResolveYoutubeChannel></ResolveYoutubeChannel>
    <SourceList class="mt-10"></SourceList>
  </div>
</template>

<script>
import ResolveYoutubeChannel from './ResolveYoutubeChannel.vue';
import SourceList from './SourceList.vue';

export default {
  components: { ResolveYoutubeChannel, SourceList },
  setup() {
    return {};
  },
};
</script>

<style></style>
