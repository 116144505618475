<template>
  <a-card>
    <p class="title">Enter YouTube Channel URL</p>
    <a-row type="flex" :gutter="16">
      <a-col flex="1">
        <a-form ref="ruleForm" :model="form" :rules="rules" @submit="reValidate">
          <a-form-item name="resolveUrl" :validate-first="true">
            <a-input
              v-model:value="form.resolveUrl"
              type="text"
              placeholder="https://www.youtube.com/channel/UCpDQCK8Yv_rY6Yz4ozg-zpw"
              autocomplete="off"
            />
          </a-form-item>
        </a-form>
      </a-col>
      <a-col
        ><a-button
          v-if="validateInfos.resolveUrl.validateStatus == 'success'"
          type="primary"
          size="default"
          :loading="isResolve"
          @click="resolveYoutubeChannel"
        >
          Resolve
        </a-button></a-col
      >
    </a-row>
    <AddYoutubeChannel
      v-if="channelInfo"
      :channel-info="channelInfo"
      :visible="addChannelVisible"
      @close="addChannelVisible = false"
      @success="addChannelSuccess"
    ></AddYoutubeChannel>
  </a-card>
</template>

<script>
import { ref, computed, reactive, watch } from 'vue';
import AddYoutubeChannel from './AddYoutubeChannel.vue';
import api from '@/services/api';
import { useStore } from 'vuex';
import { Form, notification } from 'ant-design-vue';

export default {
  components: { AddYoutubeChannel },
  setup() {
    const store = useStore();
    let isResolve = ref(false);
    const channelInfo = ref({});
    const addChannelVisible = ref(false);
    const youtubeChannels = computed(() => store.getters['sourceSetting/youtubeChannels']);
    const useForm = Form.useForm;
    const ruleForm = ref(0);
    const form = reactive({
      resolveUrl: '',
    });

    const checkExist = async () => {
      if (form.resolveUrl) {
        try {
          let result = await api.resolveYoutubeChannel(form.resolveUrl);

          let isExist = youtubeChannels.value.filter((v) => v.id === result.message.channelId)[0];
          if (isExist) return Promise.reject('Added url is already exist');
          else Promise.resolve();
        } catch (error) {
          return Promise.resolve();
        }
      } else {
        return Promise.resolve();
      }
    };

    const rules = reactive({
      resolveUrl: [
        { required: true, message: 'Please input channel url', trigger: 'change' },
        {
          pattern: new RegExp('https://www.youtube.com/channel/.+', 'g'),
          message: 'URL is incorrect or not exist',
          trigger: 'change',
        },
        {
          validator: checkExist,
          trigger: 'change',
        },
      ],
    });

    const resolveYoutubeChannel = async () => {
      isResolve.value = true;
      try {
        let result = await api.resolveYoutubeChannel(form.resolveUrl);

        channelInfo.value = {
          accountId: store.state.account.brand,
          link: result.message.link,
          name: result.message.channelName,
          id: result.message.channelId,
          profilePictureURL: result.message.profilePictureURL,
        };
        addChannelVisible.value = true;
      } catch (error) {
        notification.error({
          message: 'Error',
          description: `Resolve ${form.resolveUrl} failed.`,
        });
      }
      isResolve.value = false;
    };

    const addChannelSuccess = async () => {
      addChannelVisible.value = false;
      await store.dispatch('sourceSetting/getYoutubeChannels');
      setTimeout(() => {
        channelInfo.value = null;
      }, 200);
    };

    const { validate, validateInfos } = useForm(form, rules);
    watch(
      () => form.resolveUrl,
      (url) => {
        if (!url) {
          ruleForm.value.resetFields();
        }
      }
    );
    const reValidate = () => {
      validate();
    };

    return {
      ruleForm,
      rules,
      isResolve,
      form,
      channelInfo,
      addChannelVisible,
      resolveYoutubeChannel,
      addChannelSuccess,
      reValidate,
      validateInfos,
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: '#272B41';
}

.text-danger {
  font-family: DM Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #ff4d4f;
}

.mt-6 {
  margin-top: 6px;
}
</style>
