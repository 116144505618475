<template>
  <div>
    <ResolveFacebookPage></ResolveFacebookPage>
    <SourceList class="mt-10"></SourceList>
  </div>
</template>

<script>
import ResolveFacebookPage from './ResolveFacebookPage.vue';
import SourceList from './SourceList.vue';

export default {
  components: { ResolveFacebookPage, SourceList },
  setup() {
    return {};
  },
};
</script>

<style></style>
