<template>
  <div>
    <ResolveInstagramUser></ResolveInstagramUser>
    <SourceList class="mt-10"></SourceList>
  </div>
</template>

<script>
import ResolveInstagramUser from './ResolveInstagramUser.vue';
import SourceList from './SourceList.vue';

export default {
  components: { ResolveInstagramUser, SourceList },
  setup() {
    return {};
  },
};
</script>

<style></style>
